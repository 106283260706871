export const churchsCNIC = [
  {
    id: 0,
    region: "Rio Grande do Sul",
    city: "Três de Maio",
    img: "img/churchs-img/tres_de_maio_1.jpeg",
    gallery: [
      "img/churchs-img/tres_de_maio_1.jpeg",
      "img/churchs-img/tres_de_maio_2.jpeg",
      "img/churchs-img/tres_de_maio_3.jpeg",
      "img/churchs-img/tres_de_maio_4.jpeg",
    ],
    objectPosition: "0% 50%",
    facebook: "www.facebook.com/igrejadecristotm/",
  },
  {
    id: 1,
    region: "Rio Grande do Sul",
    city: "Pederneiras",
    img: "img/churchs-img/pederneiras_1.jpeg",
    gallery: [
      "img/churchs-img/pederneiras_1.jpeg",
      "img/churchs-img/pederneiras_2.jpeg",
      "img/churchs-img/pederneiras_3.jpeg",
    ],
    objectPosition: "35% 50%",
  },
  {
    id: 2,
    region: "Paraná",
    city: "Arapongas",
    img: "img/churchs-img/arapongas_1.jpeg",
    gallery: [
      "/img/churchs-img/arapongas_1.jpeg",
      "/img/churchs-img/arapongas_2.jpeg",
    ],
    facebook:
      "www.facebook.com/Igreja-Evangélica-de-Cristo-L-Arapongas-109257064336714/",
  },

  {
    id: 4,
    region: "Rio Grande do Sul",
    city: "Espírito Santo - Alegria",
    img: "img/churchs-img/espirito_santo_alegria_1.jpeg",
    gallery: [
      "img/churchs-img/espirito_santo_alegria_1.jpeg",
      "img/churchs-img/espirito_santo_alegria_2.jpeg",
    ],
    objectPosition: "40% 50%",
    paddingTagB: "100px 0px 0px 0px",
    facebook: "www.facebook.com/IgrejaDeCristoEspSanto/",
  },
  {
    id: 5,
    region: "Rio Grande do Sul",
    city: "Horizontina",
    img: "img/churchs-img/horizontina_1.jpeg",
    gallery: [
      "img/churchs-img/horizontina_1.jpeg",
      "img/churchs-img/horizontina_2.jpeg",
    ],
    objectPosition: "53% 50%",
    facebook: "www.facebook.com/Igrejadecristohorizontina/",
  },
  {
    id: 6,
    region: "Rio Grande do Sul",
    city: "Independência",
    img: "img/churchs-img/independencia_1.jpeg",
    gallery: [
      "img/churchs-img/independencia_1.jpeg",
      "img/churchs-img/independencia_2.jpeg",
    ],
    objectPosition: "73% 50%",
    facebook: "www.facebook.com/iec.independencia",
  },
  {
    id: 7,
    region: "Santa Catarina",
    city: "Jaraguá do Sul",
    img: "img/churchs-img/jaragua_1.jpeg",
    gallery: [
      "img/churchs-img/jaragua_1.jpeg",
      "img/churchs-img/jaragua_2.jpeg",
    ],
    objectPosition: "5% 50%",
    facebook: "www.facebook.com/iec.jaraguadosul.9",
  },
  {
    id: 8,
    region: "Paraná",
    city: "Marechal Cândido Rondon",
    img: "img/churchs-img/marechal_1.jpeg",
    gallery: [
      "img/churchs-img/marechal_1.jpeg",
      "img/churchs-img/marechal_2.jpeg",
    ],
    objectPosition: "32% 50%",
    paddingTagB: "100px 0px 0px 0px",
    facebook:
      "www.facebook.com/Igreja-Evang%C3%A9lica-de-Cristo-Marechal-C%C3%A2ndido-Rondon-104953064669365/",
  },
  {
    id: 9,
    region: "Paraná",
    city: "Nova Santa Rosa",
    objectPosition: "20% 50%",
    img: "img/churchs-img/nova_santa_rosa_1.jpeg",
    gallery: [
      "img/churchs-img/nova_santa_rosa_1.jpeg",
      "img/churchs-img/nova_santa_rosa_2.jpeg",
    ],
    facebook: "www.facebook.com/iec.novasantarosa",
  },
  {
    id: 10,
    region: "Rio Grande do Sul",
    city: "Novo Machado",
    objectPosition: "45% 50%",
    img: "img/churchs-img/novo_machado_1.jpeg",
    gallery: [
      "img/churchs-img/novo_machado_1.jpeg",
      "img/churchs-img/novo_machado_2.jpeg",
    ],
    facebook: "www.facebook.com/Igrejadecristonovomachado/",
  },
  {
    id: 11,
    region: "Rio Grande do Sul",
    city: "Caxias do Sul",
    img: "img/churchs-img/caxias_2.jpeg",
    gallery: [
      "/img/churchs-img/caxias_2.jpeg",
      "/img/churchs-img/caxias_3.jpeg",
    ],
    objectPosition: "58% 50%",
  },
  {
    id: 12,
    region: "Rio Grande do Sul",
    city: "Porto Xavier",
    img: "img/churchs-img/porto_xavier_1.jpeg",
    gallery: [
      "img/churchs-img/porto_xavier_1.jpeg",
      "img/churchs-img/porto_xavier_2.jpeg",
    ],
    facebook: "www.facebook.com/igrejadecristodeportoxavier/",
  },
  {
    id: 13,
    region: "Rio Grande do Sul",
    city: "Santa Rosa",
    img: "img/churchs-img/santa_rosa_1.jpeg",
    gallery: [
      "img/churchs-img/santa_rosa_1.jpeg",
      "img/churchs-img/santa_rosa_2.jpeg",
    ],
    facebook:
      "www.facebook.com/Igreja-de-Cristo-de-Santa-Rosa-1711277559108659/",
  },
  {
    id: 14,
    region: "Paraná",
    city: "Toledo",
    img: "img/churchs-img/toledo_1.jpeg",
    gallery: ["img/churchs-img/toledo_1.jpeg", "img/churchs-img/toledo_2.jpeg"],
    objectPosition: "5% 50%",
    facebook: "www.facebook.com/IECTOficial/",
  },
  {
    id: 15,
    region: "Rio Grande do Sul",
    city: "Alecrim",
    img: "img/churchs-img/alecrim_1.jpeg",
    objectPosition: "40% 50%",
    gallery: [
      "/img/churchs-img/alecrim_1.jpeg",
      "/img/churchs-img/alecrim_2.jpeg",
    ],
  },
  {
    id: 16,
    region: "Rio Grande do Sul",
    city: "Tuparendi",
    img: "img/churchs-img/tuparendi_1.jpeg",
    gallery: [
      "img/churchs-img/tuparendi_1.jpeg",
      "img/churchs-img/tuparendi_2.jpeg",
    ],
    facebook: "www.facebook.com/Igreja-de-Cristo-Tuparendi-106610894603179/",
  },
  {
    id: 17,
    region: "Rio Grande do Sul",
    city: "Viamão",
    img: "img/churchs-img/viamao_1.jpeg",
    gallery: [
      "img/churchs-img/viamao_1.jpeg",
      "img/churchs-img/viamao_2.jpeg",
      "img/churchs-img/viamao_3.jpeg",
    ],
  },
  {
    id: 18,
    region: "Rio Grande do Sul",
    city: "Vicente Dutra",
    img: "img/churchs-img/vicente_dutra_1.jpeg",
    gallery: [
      "img/churchs-img/vicente_dutra_1.jpeg",
      "img/churchs-img/vicente_dutra_2.jpeg",
      "img/churchs-img/vicente_dutra_3.jpeg",
    ],
    facebook: "www.facebook.com/iecdevicentedutra",
  },
  {
    id: 19,
    region: "Rio Grande do Sul",
    city: "Senador Salgado Filho",
    img: "img/churchs-img/senador_s_filho_1.jpeg",
    gallery: [
      "img/churchs-img/senador_s_filho_1.jpeg",
      "img/churchs-img/senador_s_filho_2.jpeg",
    ],
    facebook:
      "www.facebook.com/Igreja-de-Cristo-Senador-Salgado-Filho-RS-108801891049478/",
  },
  {
    id: 20,
    region: "Rio Grande do Sul",
    city: "Gravataí/Canoas",
    img: "img/churchs-img/gravatai_canoas_1.jpeg",
    gallery: [
      "img/churchs-img/gravatai_canoas_1.jpeg",
      "img/churchs-img/gravatai_canoas_2.jpeg",
      "img/churchs-img/gravatai_canoas_3.jpeg",
      "img/churchs-img/gravatai_canoas_4.jpeg",
    ],
    facebook: "www.facebook.com/igrejadecristogravatairs/",
  },
  {
    id: 21,
    region: "Rio Grande do Sul",
    city: "Frederico Westphalen",
    img: "img/churchs-img/frederico_westphalen_1.jpeg",
    gallery: [
      "img/churchs-img/frederico_westphalen_1.jpeg",
      "img/churchs-img/frederico_westphalen_2.jpeg",
      "img/churchs-img/frederico_westphalen_3.jpeg",
    ],
    facebook: "www.facebook.com/iecfredereicowestphalen",
  },
];
