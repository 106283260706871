import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/pt-br";

import { useHistory } from "react-router-dom";

require("react-big-calendar/lib/css/react-big-calendar.css");

const test = [
  {
    id: 1,
    title: "Conferência de Fé",
    allDay: false,
    start: new Date(2021, 0, 7),
    end: new Date(2021, 0, 11),
  },
];

export default function Agenda() {
  const [events, setEvents] = useState(test);
  const localizer = momentLocalizer(moment);

  let MyOtherNestedComponent = () => <div></div>;

  let MyCustomHeader = ({ label }) => (
    <div>
      <div>{label.toUpperCase()}</div>
      <MyOtherNestedComponent />
    </div>
  );

  let history = useHistory();

  const MyCalendar = (props) => (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-center pt-10 pb-10">
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              components={{
                day: { header: MyCustomHeader },
                week: { header: MyCustomHeader },
                month: { header: MyCustomHeader },
              }}
              messages={{
                next: "Próximo",
                previous: "Anterior",
                today: "Hoje",
                month: "Mês",
                week: "Semana",
                day: "Dia",
                allDay: "Todo dia",
                agenda: "Agenda",
                date: "Data",
                time: "Hora",
                event: "Evento", // Or anything you want
                showMore: (total) =>
                  `+ ${total} événement(s) supplémentaire(s)`,
              }}
              onSelectEvent={(event) => history.push("/evento/" + event.id)}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="breadcumb-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcumb-text text-center mx-auto my-5">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2Fagenda.png?alt=media&token=9285db37-8fbd-4345-9d21-348fd7b4222d"
                  width="8%"
                />
                <h3>Agenda</h3>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>

      <MyCalendar />
    </>
  );
}
