import React, { useEffect } from "react";

import { Router } from "react-router-dom";
import Routes from "./routes";

import history from "./services/history";

// import { getProduct } from "./services/product";
function App() {
  // const handleProducts = async () => {
  //   const res = await getProduct();
  //   console.log(res);
  // };

  // useEffect(() => {
  //   handleProducts();
  // }, [handleProducts()]);

  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
}

export default App;
