import React from "react";

export default function NotFound() {
  return (
    <>
      <div className="page-notfound">
        <div className="box_1">
          <span>404</span>
          <span>Página não encontrada.</span>
        </div>
        <div className="box_2">
          <p>
            E buscar-me-eis, e me achareis, quando me buscardes com todo o vosso
            coração.<span> Jeremias 29:13</span>
          </p>
        </div>
      </div>
    </>
  );
}

NotFound.propTypes = {
  // bla: PropTypes.string,
};

NotFound.defaultProps = {
  // bla: 'test',
};
