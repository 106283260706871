import React, { useState } from "react";
import { booksCNIC } from "./../../stubs/books.js";
import BoletimContent from "../../components/BoletimContent";

export default function Boletins() {
  const [books, setBooks] = useState(booksCNIC);

  return (
    <>
      <div className="breadcumb-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcumb-text text-center mx-auto my-5">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2Fboletim.png?alt=media&token=8f7f0253-b93e-4150-88dd-696c398ca50c"
                  width="8%"
                />
                <h3>Boletins Informativos</h3>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>

      <section className="church-activities-area">
        <div className="container">
          <div className="row boletins py-5">
            {books.map((book, i) => (
              <div className="col-md-3 col-sm-12">
                <BoletimContent
                  content={{
                    url: book.url_book,
                    title: book.title,
                    cover: book.img_capa,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
