import React from 'react';
import PropTypes from 'prop-types';
//import { Test } from './Login.styles';

const Login = (props) => (
  <div className="LoginWrapper">
    LoginWrapper
  </div>
);

Login.propTypes = {
  // bla: PropTypes.string,
};

Login.defaultProps = {
  // bla: 'test',
};

export default Login;
