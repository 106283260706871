import React, { useState } from "react";

export default function Radio({
  id,
  name,
  link,
  image,
  top
}) {
  const [maximizeRadio, setMaximizeRadio] = useState(false);
  const [playRadio, setPlayRadio] = useState(false);
  const [gif, setGif] = useState(
    "https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-gif%2FBarras-Audio-03.gif.12d1fbf797d407de023d23f4fb3d26a5.gif.ec98e53c5cd185ff5e3f734e01ecbc1c.gif?alt=media&token=e13fd22d-64e6-419f-9853-a80504b13246"
  );

  return (
    <>
      <div
        data-toggle="tooltip"
        data-placement="right"
        title={name}
        className="audio-player"
        style={{
          top: top,
          left: maximizeRadio ? "-45px" : "-255px",
          background: `url(${
            playRadio && gif
          }), linear-gradient(90deg, #0067c9 0%, #179cc7 80%)`,
        }}
      >
        <div
          id={id === 0 ? "play-btn-vida" : "play-btn-devocional"}
          onClick={() => {
            setPlayRadio(!playRadio);
          }}
        ></div>
        <div className="audio-wrapper" id="player-container">
          <audio id={id === 0 ? "player-vida" : "player-devocional"}>
            <source
              src={link}
              // src="https://player.srvaudio.com.br/player-ssl/11270/;?1596905668077"
              type="audio/mp3"
            />
          </audio>
        </div>
        <div className="player-controls scrubber">
          <span>{name}</span>
        </div>
        <div
          onClick={() => {
            setMaximizeRadio(!maximizeRadio);
          }}
          className="album-image"
          style={{
            backgroundImage: `url(${image})`,
          }}
        ></div>
      </div>
    </>
  );
}
