import React, { useEffect, useState } from "react";
import FB, { FacebookApiException } from "fb";
import { NavLink } from "react-router-dom";

export default function Galeria() {
  const [albums, setAlbums] = useState();

  const access_token =
    "EAALE5xU7VgkBAIjvSGQSUw54hLzoHra9ZAPdkjPD5iuq3JPLHXOX2g75msXnZA8J91FZBlnDJfZCILFKTjWOZAYiCSENN3yKRmJFAUOMCrpbZCiODpPIOZAoipcT5ZB4E0sY6dZBp43M3fqyZCDyuyQU210VDdFCjusNUqlzLVIUd9ZAEafC8Q061Dp";
  useEffect(() => {
    if (!albums) {
      FB.api(
        `/me/albums?fields=picture,name,created_time,description,count&access_token=${access_token}`,
        function (response) {
          if (response && !response.error) {
            setAlbums(response);
          }
        }
      );
    }
  });

  return (
    <>
      <div className="breadcumb-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcumb-text text-center mx-auto my-5">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2F9663a3aa79a6eb28d1d8722a34f55568.png?alt=media&token=d2aa2fe4-008e-4810-bc6e-fe4ce7f7b1b7"
                  width="8%"
                />
                <h3>Galeria</h3>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <section className="container mt-30 mb-50">
        <div className="container">
          <div className="row">
            <h4>
              <strong>Todos os álbuns</strong>
            </h4>
            <hr
              className="pt-0"
              style={{ borderTop: "1px solid rgb(0 0 0 / 15%)", width: "100%" }}
            />
          </div>
        </div>
        <div className="container mt-20">
          <div className="row">
            {albums &&
              albums?.data.map(
                (album, i) =>
                  album.id != "497119357367628" &&
                  album.id != "497256504020580" && (
                    <div className="col-md-3 mb-3" key={i}>
                      <div className="card albums" style={{ height: "340px" }}>
                        <img
                          style={{ objectFit: "cover", height: "177px" }}
                          className="card-img-top"
                          src={album?.picture?.data?.url}
                          alt="Card image cap"
                        />
                        <div className="card-body">
                          <h5
                            className="card-title"
                            style={{ fontSize: "14px", fontWeight: 600 }}
                          >
                            {(album?.name).replace(
                              /(^\w|\s\w)(\S*)/g,
                              (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
                            )}
                          </h5>
                          <p className="card-text">{album?.count} itens</p>
                        </div>
                        <NavLink
                          to={"galeria/album/" + album.id}
                          className="btn faith-btn active btn-card"
                        >
                          Ver álbum
                        </NavLink>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
      </section>
    </>
  );
}
