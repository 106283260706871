import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";

import axios from "axios";

import CarouselHome from "../../components/CarouselHome";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import BoletimContent from "../../components/BoletimContent";

import { ENV } from "./../../config/environments";
import { churchsCNIC } from "./../../stubs/churchs.js";
import { booksCNIC } from "./../../stubs/books.js";

import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox";

export default function Home() {
  let history = useHistory();

  const [verseRandom, setVerseRandom] = useState();
  const [churchs, setChurchs] = useState(churchsCNIC);
  const [books, setBooks] = useState(booksCNIC);

  const config = {
    headers: { Authorization: `Bearer ${ENV.tokenBible}` },
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const result = await axios(
        "https://www.abibliadigital.com.br/api/verses/nvi/random",
        config
      );
      setVerseRandom(result.data);
    }
    fetchData();
  }, []);

  const ContainerAgenda = () => (
    <div className="faith-cta-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="cta-text d-flex justify-content-between align-items-center">
              <h5>
                Saiba como, onde e quando nos reunimos. Esperamos te ver em
                breve!
              </h5>
              <NavLink to="/agenda" className="btn faith-btn">
                Agenda
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const ContainerVersiculo = () => (
    <section className="faith-about-area section-padding-100-0">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="about-content">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2Fbible.png?alt=media&token=d7dc601a-1e0e-413a-8a25-a6a0562c8104"
                alt=""
              />
              <h2>
                "{verseRandom?.text}" {verseRandom?.book?.name}{" "}
                {verseRandom?.chapter}:{verseRandom?.number}
              </h2>
              {/* <h6>
                Jesus lhes respondeu: “Nem ele pecou, nem seus pais; mas foi
                para que as obras de Deus fossem reveladas na vida dele. Eu devo
                fazer as obras daquele que me enviou, enquanto é dia; a noite
                vem, quando ninguém pode trabalhar. João 9:3-4
              </h6> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const NextActivities = () => (
    <div onClick={(event) => history.push("/evento/1")} className="single-upcoming-events d-flex align-items-center">
      <div className="event-date-thumbnail d-flex align-items-center">
        <div className="event-date">
          <h6>
            07 á 10 <br /> Janeiro <br /> 2023
          </h6>
        </div>
        <div
          className="event-thumbnail bg-img"
          style={{
            backgroundImage: `url(https://cursinhoparamedicina.com.br/wp-content/uploads/2022/10/Paisagem-1.jpg)`,
          }}></div>
      </div>
      <div className="events-content">
        <NavLink to={"evento/" + 1}>
          <h6>Conferencia de Fé</h6>
        </NavLink>
        <p>Local: Linha Arapongas, Toledo, Paraná</p>
      </div>
    </div>
  );

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const CarouselChurchs = () => (
    <div className="col-12">
      <Carousel
        responsive={responsive}
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={600}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        // infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={10}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
        itemClass=" "
      >
        {churchs
          .sort((a, b) => (a.region > b.region ? -1 : 1))
          .map((church, i) => (
            <SimpleReactLightbox key={i}>
              <SRLWrapper>
                <div className="carousel-item-church mr-4" data-attribute="SRL">
                  {church.gallery &&
                    church.gallery.map((photo, index) => (
                      <img
                        key={index}
                        src={photo}
                        alt={church.city + " - " + church.region}
                        style={{
                          objectPosition: church?.objectPosition,
                          display: index > 0 ? "none" : "block",
                        }}
                      />
                    ))}
                  {church?.facebook && (
                    <NavLink
                      className="fb-church animate__animated animate__fadeIn"
                      target="_blank"
                      to={"//" + church?.facebook}
                      // src=""
                    ></NavLink>
                  )}
                  <span>{church.city}</span>
                  <b>{church.region}</b>
                </div>
              </SRLWrapper>
            </SimpleReactLightbox>
          ))}
      </Carousel>
      ;
    </div>
  );

  return (
    <>
      <CarouselHome></CarouselHome>

      <ContainerAgenda></ContainerAgenda>
      <ContainerVersiculo></ContainerVersiculo>

      <section className="church-activities-area section-padding-100-0 mb-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading text-center mx-auto">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2Fcross.png?alt=media&token=f597e491-fb4f-451f-b976-c56b5cde41af"
                  alt=""
                />
                <h3>Próximas Atividades</h3>
                <p>Acompanhe as últimas atividades realizadas pela CNIC </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="upcoming-events">
                <NextActivities></NextActivities>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="upcoming-events">
                {/* <NextActivities></NextActivities>
                <NextActivities></NextActivities>
                <NextActivities></NextActivities>
                <NextActivities></NextActivities> */}
              </div>
            </div>
          </div>

          <div className="row justify-content-center mt-5">
            <div>
              <div className="all-events-btn">
                <NavLink to="/agenda" className="btn faith-btn active">
                  Ver Agenda
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="donate-our-charities section-padding-100 bg-img bg-overlay"
        style={{
          backgroundImage: `url(img/bg-img/bg-10.jpg)`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading white text-center mx-auto">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/logo.png?alt=media&token=368c1ced-bd38-46ae-bae7-7b93a2915181"
                  width="10%"
                  alt=""
                />
                <h3>CNIC - Convenção Nacional das Igrejas De Cristo</h3>
                <p>Conheça sobre cada uma de nossas igrejas</p>
              </div>
            </div>
          </div>

          <div className="row" tabIndex="0" id="carousel-churchs" style={{outline: "none"}}>
            <CarouselChurchs />
          </div>
        </div>
      </section>

      <section className="church-activities-area section-padding-100-0 mb-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading text-center mx-auto">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2Fboletim.png?alt=media&token=8f7f0253-b93e-4150-88dd-696c398ca50c"
                  alt=""
                />
                <h3>Boletins Informativos</h3>
                <p>
                  Mantenha-se atualizado através do boletim informativo digital
                </p>
              </div>
            </div>
          </div>
          <div className="row boletins py-0">
            {books.map(
              (book, i) =>
                i < 4 && (
                  <div className="col-md-3 col-sm-12" key={i}>
                    <BoletimContent
                      content={{
                        url: book.url_book,
                        title: book.title,
                        cover: book.img_capa,
                      }}
                    />
                  </div>
                )
            )}
          </div>
          <div className="row justify-content-center mt-5">
            <div>
              <div className="all-events-btn">
                <NavLink to="/boletins" className="btn faith-btn active">
                  Ver Todos
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
