import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import PreLoader from "../../components/PreLoader";
import { NavLink } from "react-router-dom";

import { MobilePDFReader, PDFReader } from "react-read-pdf";

export default function BoletimContent(props) {
  const MySwal = withReactContent(Swal);

  const modalReader = (url, title) =>
    MySwal.fire({
      width: "100%",
      padding: "0",
      backdrop: `#000000c7`,
      html: (
        <div style={{ overflow: "scroll", height: 600 }}>
          <MobilePDFReader url={url} isShowHeader={false} />
          {/* <PDFReader url="files/pdfs/03.pdf" title="" /> */}
          {/* <PDFViewer
            document={{
              url: url,
            }}
            canvasCss="customCanvas"
            loader={<PreLoader />}
          /> */}
        </div>
      ),
      showCloseButton: true,
      showConfirmButton: false,
      heightAuto: true,
    });
  return (
    <>
      <a target="_blank" href={props.content.url}>
        <div className="boletim-item">
          <img src={props.content.cover} alt={props.content.title} />
          <span className="animate__animated animate__fadeIn btn faith-btn">
            Ler
          </span>
        </div>
      </a>
      <div
        className="boletim-item-mobile"
        onClick={() => modalReader(props.content.url, props.content.title)}
      >
        <img src={props.content.cover} alt={props.content.title} />
        <span className="animate__animated animate__fadeIn btn faith-btn">
          Ler
        </span>
      </div>
    </>
  );
}
