import React from "react";
import PropTypes from "prop-types";
//import { Test } from './Sobre.styles';

const Sobre = (props) => (
  <>
    <div className="breadcumb-area">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="breadcumb-text text-center mx-auto my-5">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2Fchurch.png?alt=media&token=dd9c8c49-b87a-4419-b2f8-32cd0f2f49d5"
                width="8%"
              />
              <h3>Sobre a Igreja de Cristo</h3>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
    <iframe
      className="mt-5"
      src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=ztjqj9przp"
      width="100%"
      height="750"
      seamless="seamless"
      scrolling="no"
      frameBorder="0"
      allowFullScreen
    ></iframe>
    <iframe
      className="mt-5 mb-5"
      src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=vtiqkcugvu"
      width="100%"
      height="480"
      seamless="seamless"
      scrolling="no"
      frameBorder="0"
      allowFullScreen
    ></iframe>
  </>
);

Sobre.propTypes = {
  // bla: PropTypes.string,
};

Sobre.defaultProps = {
  // bla: 'test',
};

export default Sobre;
