import React from "react";
const photos = [
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/events-img%2Fconferencia_de_fe%2Fa202712d-a0d3-480a-9d16-c63d55632207.jpg?alt=media&token=ac278299-ed4f-4c1e-8c35-24c83acb238e",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/events-img%2Fconferencia_de_fe%2F4098d098-9787-4537-b136-eab668aeeca1.jpg?alt=media&token=e9a83b48-b48a-4f16-83ee-305bc79b0d46",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/events-img%2Fconferencia_de_fe%2F16b745ad-ecb0-4e33-9605-e3420e51be11.jpg?alt=media&token=a464a29b-45a6-454d-b280-eb1225375c24",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/events-img%2Fconferencia_de_fe%2F7d65c09a-788d-4eb6-bfd2-4d622debbd9f.jpg?alt=media&token=01ed23a8-b351-4cd4-8a34-3b75e5b0e588",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/events-img%2Fconferencia_de_fe%2F7ceedcf2-bccd-43c7-8da4-b2d4809c0d65.jpg?alt=media&token=579b4499-826c-4fa4-aa71-64dfc3e7a1a5",
  },
  {
    src:
      "https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/events-img%2Fconferencia_de_fe%2F88d63d12-a4a3-4678-b013-87dd35f1a900.jpg?alt=media&token=11bf6173-113f-4319-b90f-970b2cd579e1",
  },
];
const CarouselPhotosEvent = () => (
  <div
    id="carouselExampleIndicators"
    className="carousel slide carousel-event"
    data-ride="carousel"
  >
    <ol className="carousel-indicators">
      {photos.map((photo, i) => (
        <>
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to={i}
            className={i === 0 && "active"}
          ></li>
        </>
      ))}
    </ol>
    <div className="carousel-inner">
      {photos.map((photo, i) => (
        <div
          className={i === 0 ? "carousel-item active" : "carousel-item"}
          key={i}
        >
          <img src={photo?.src} className="d-block w-100" alt={i} />
        </div>
      ))}
    </div>
    <a
      className="carousel-control-prev"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="prev"
    >
      <button
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left ml-3"
      ></button>{" "}
      <span className="sr-only">Previous</span>
    </a>
    <a
      className="carousel-control-next"
      href="#carouselExampleIndicators"
      role="button"
      data-slide="next"
    >
      <button
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right mr-3"
      ></button>
      <span className="sr-only">Next</span>
    </a>
  </div>
);

const Evento = (props) => (
  <>
    <div className="breadcumb-area">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="breadcumb-text text-center mx-auto my-5">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2Fchurch.png?alt=media&token=dd9c8c49-b87a-4419-b2f8-32cd0f2f49d5"
                width="8%"
              />
              <h3>Conferência de Fé</h3>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>

    <div className="container event-schedule mt-5 mb-5">
      <div className="row justify-content-between">
        <div className="col-md-6 col-sm-12">
          <h2>Conferência de Fé</h2>
          <p className="mt-5">
            A Convenção Nacional das Igrejas de Cristo, (CNIC), vem por meio
            desta convidar a todos pastores, bem como as Igrejas que estão sob
            os seus cuidados pastorais para a nossa Conferencia de Fé que se
            realizara nos dias 07 á 10 de Janeiro de 2021, nesta oportunidade a
            Conferencia será realizada em Linha Arapongas, Toledo, Paraná.
            Preletores deste evento serão Pastores da Igreja de Cristo no
            brasil.
          </p>
          <u className="mt-5">TEMA: MISSÕES, UMA OBRA QUE NÃO PODE PARAR. </u>
        </div>
        <div className="col-md-5 col-sm-12">
          {/* <div className="event-schedule-img">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/events-img%2Fconferencia_de_fe%2Fa202712d-a0d3-480a-9d16-c63d55632207.jpg?alt=media&token=ac278299-ed4f-4c1e-8c35-24c83acb238e"
              alt="Conferência de Fé"
            />
          </div> */}
          <CarouselPhotosEvent />
        </div>
      </div>
      <hr className="mt-5 mb-5" />
      <div className="col-md-7 col-sm-12 pb-4">
        <div>
          <strong>Segue a Programação:</strong>
        </div>
        <ul className="mb-3 mt-3 event-schedule-list">
          <li>
            07/01/2021: 19:30 horas Assembleia Geral Ordinária da Convenção
          </li>
          <li> 08/01/2021: 19:30 Horas, Culto de Boas Vindas.</li>
          <li>
            09/01/2021: 09:30 horas, Culto de Avivamento 19:30 Horas, Culto de
            Avivamento.
          </li>
          <li>
            10/01/2021: 09:30 Horas, Culto de Missões e Encerramento da
            Conferencia.
          </li>
        </ul>
      </div>
    </div>
  </>
);

export default Evento;
