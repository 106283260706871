import React from "react";

export default function PreLoader() {
  return (
    <div id="preloader">
      <div className="circle">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/logo.png?alt=media&token=368c1ced-bd38-46ae-bae7-7b93a2915181"
          alt="Pré Loader CNIC"
        />
      </div>
    </div>
  );
}
