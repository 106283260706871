import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function Footer() {
  useEffect(() => {}, []);

  return (
    <footer className="footer-area">
      <div
        className="main-footer-area section-padding-100-0 bg-img foo-bg-overlay"
        style={{
          backgroundImage: `url(img/bg-img/bg-1.jpg)`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-xl-5">
              <div className="d-flex flex-wrap mb-100">
                <div className="footer-widget">
                  <div className="widget-title">
                    <a href="#">
                      <img
                        src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2Flogo%20iec.jpeg?alt=media&token=ac2ba95b-afce-42a8-bc33-62f5533c08c4"
                        width="40%"
                        alt=""
                      />
                    </a>
                  </div>
                  {/* <div className="footer-social-info">
                    <a href="#">
                      <i className="fa fa-google-plus"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-pinterest"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </div> */}

                  <div
                    class="fb-page"
                    data-href="https://www.facebook.com/convencaonacionaligrejasdecristo/"
                    data-tabs="timeline"
                    data-width=""
                    data-height=""
                    data-small-header="false"
                    data-adapt-container-width="true"
                    data-hide-cover="false"
                    data-show-facepile="true"
                  >
                    <blockquote
                      cite="https://www.facebook.com/convencaonacionaligrejasdecristo/"
                      class="fb-xfbml-parse-ignore"
                    >
                      <a href="https://www.facebook.com/convencaonacionaligrejasdecristo/">
                        CNIC - Convenção Nacional das Igrejas De Cristo.
                      </a>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-xl-4">
              <div className="footer-widget mb-100">
                <div className="widget-title">
                  <h6>Contatos</h6>
                </div>
                {/* 
                <div className="single-contact-area mb-30">
                  <p>Endereço:</p>
                  <span>
                    1481 Creekside Lane Avila <br />
                    Beach, CA 93424{" "}
                  </span>
                </div> */}

                <div className="single-contact-area mb-30">
                  <p>Telefones:</p>
                  <span>(55) 99148-8899</span>
                  <span>(55) 99921-9342</span>
                  <span>(45) 99944-9113</span>
                </div>

                <div className="single-contact-area mb-30">
                  <p>Emails:</p>
                  <span>Convencao.cnic@hotmail.com</span>
                  <span>marciowoltmann@yahoo.com.br</span>
                </div>
                <div className="single-contact-area mb-30">
                  <p>Ofertas e Doações:</p>
                  <span>CNPJ: 03.805.759/0001-30</span>
                  <span>
                    Banco: <strong>Nº 748</strong> - Sicredi
                  </span>
                  <span>
                    Agência: <strong>0307</strong> - Santa Rosa/RS
                  </span>
                  <span>
                    Conta: <strong>51489-6</strong>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-xl-3">
              <div className="footer-widget mb-100">
                <div className="widget-title">
                  <h6>Menu</h6>
                </div>
                <nav>
                  <ul className="useful-links">
                    <li>
                      <NavLink to="/home">Início</NavLink>
                    </li>
                    <li>
                      <NavLink to="/agenda">Agenda</NavLink>
                    </li>
                    <li>
                      <NavLink to="/galeria">Galeria</NavLink>
                    </li>
                    <li>
                      <NavLink to="/videos">Videos</NavLink>
                    </li>
                    <li>
                      <NavLink to="/pedidos-de-oracao">
                        Pedido de oração
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/sobre">Sobre a Igreja de Cristo</NavLink>
                    </li>
                    <li>
                      <NavLink to="/boletins">Boletins informativos</NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            {/* <div className="col-12 col-sm-6 col-xl-3">
              <div className="footer-widget mb-100">
                <div className="widget-title">
                  <h6>Why Choose us?</h6>
                </div>
                <p>
                  Fusce nec ante vitae lacus aliquet vulputate. Donec
                  scelerisque accumsan molestie. Vestibulum ante ipsum primis in
                  faucibus orci luctus et ultrices posuere cubilia Curae; Cras
                  sed accumsan neque.
                </p>
                <a href="#" className="btn faith-btn mt-70">
                  Sunday Workship: 10:30 AM
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="corp">
        <span className="text-center">
          Uma família no Amor Deus. &copy; CNIC {new Date().getFullYear()}
        </span>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  // bla: PropTypes.string,
};

Footer.defaultProps = {
  // bla: 'test',
};
