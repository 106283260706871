import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Radio from "../components/Radio";
import ScrollToTop from "../components/ScrollToTop";

import Header from "../components/Header";
import Footer from "../components/Footer";
import NotFound from "../components/NotFound";

import HomePage from "../pages/Home";
import AgendaPage from "../pages/Agenda";
import LoginPage from "../pages/Login";
import BoletinsPage from "../pages/Boletins";
import EventoPage from "../pages/Evento";
import VideosPage from "../pages/Videos";
import PedidosDeOracao from "../pages/PedidosDeOracao";
import SobrePage from "../pages/Sobre";
import GaleriaPage from "../pages/Galeria";
import FotosPage from "../pages/Galeria/Fotos";

export default function IgrejaDeCristoRoutes() {
  const NotFoundRedirect = () => <Redirect to="/not-found" />;

  return (
    <Router>
      <Header></Header>

      <Radio
        id={0}
        name="Rádio Vida FM"
        link="https://player.srvaudio.com.br/player-ssl/11270/;?1605306172764"
        image="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2Fradio_vida.jpg?alt=media&token=9fa262fb-68b5-4e02-bbb4-c93a793fda32"
        top="65%"
      />
      <ScrollToTop />
      <Switch>
        <Redirect exact from={"/"} to={"/home"} />
        <Route path="/home" component={HomePage} />
        <Route path="/agenda" component={AgendaPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/boletins" component={BoletinsPage} />
        <Route path="/galeria" exact component={GaleriaPage} />
        <Route path="/galeria/album/:id" exact component={FotosPage} />
        <Route path="/Videos" component={VideosPage} />
        <Route path="/Videos/cultos" component={VideosPage} />
        <Route path="/pedidos-de-oracao" component={PedidosDeOracao} />
        <Route path="/sobre" component={SobrePage} />
        <Route path="/evento/:id" component={EventoPage} />
        <Route path="/not-found" component={NotFound} />
        <Route component={NotFoundRedirect} />
        {/* <Route path="/magic-login/:token" component={MagicLoginRedirectPage} /> */}
        {/* {routes.map((page, index) => (
            <PrivateRoute
              exact
              key={index}
              path={`/${page.path}`}
              component={page.component}
            />
          ))} */}
      </Switch>
      <Footer></Footer>
    </Router>
  );
}
