import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { videosCNIC } from "./../../stubs/videos.js";

const MySwal = withReactContent(Swal);

export default function Videos() {
  const [videos, setVideos] = useState(videosCNIC);
  const location = useLocation();

  function playVideo(id) {
    MySwal.fire({
      // width: "20%",
      padding: "45px",
      // backdrop: `#00000000`,
      html: <Vimeo video={id} responsive autoplay />,
      showCloseButton: true,
      showConfirmButton: false,
    });
  }

  useEffect(() => {
    console.log(location);
  }, [location]);
  return (
    <>
      <div className="breadcumb-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcumb-text text-center mx-auto my-5">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2Fvideos.png?alt=media&token=92577b16-9de0-45e6-b4a1-e7a1bc3d4f76"
                  width="8%"
                />
                <h3>Videos</h3>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <section className="church-activities-area section-padding-100-0 mb-100">
        <div className="container">
          <div className="row videos">
            {videos.map((video, i) =>
              location.pathname === "/videos" ? (
                <div className="col-md-4 col-sm-12" key={i}>
                  <div
                    className="video-item"
                    onClick={() => playVideo(video.id_vimeo)}
                  >
                    <>
                      <img
                        src={video.miniature}
                        className="video-miniature"
                        alt={video.title}
                      />
                      <span className="animate__animated animate__fadeIn btn faith-btn">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2Fplay-icon-firebase.png?alt=media&token=0f815add-850e-47e2-a3c7-4fdf38d6ae52"
                          width="47px"
                          alt=""
                          className="animate__animated animate__fadeIn"
                        />
                      </span>
                      <div className="video-legend">{video.title}</div>
                    </>
                  </div>
                </div>
              ) : (
                <div key={i}>Nenhum video encontrado</div>
              )
            )}
          </div>
        </div>
      </section>
    </>
  );
}
