import React, { useState } from "react";
import AwesomeSlider from "react-awesome-slider";

import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import CoreStyles from "react-awesome-slider/src/core/styles.scss";
import AnimationStyles from "react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss";

import { useHistory } from "react-router-dom";

export default function CarouselHome() {
  const [backgrounds, setBackgrounds] = useState([
    {
      data: "07.01.2024 à 10.01.2024",
      img:
        "https://static.todamateria.com.br/upload/pa/is/paisagem-natural-og.jpg",
      title: "Conferência de Fé",
      description: "TEMA: MISSÕES, UMA OBRA QUE NÃO PODE PARAR.",
      id: "1",
    },
    {
      data: "07.02.2026 à 10.02.2026",
      img:
        "https://static.escolakids.uol.com.br/2023/08/paisagem-natural.jpg",
      title: "Congresso de Jovens",
      description: "TEMA: MISSÕES, UMA OBRA QUE NÃO PODE PARAR.",
      id: "2",
    },
  ]);


  
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  let history = useHistory();

  return (
    <>
      <section className="hero-area">
        <AutoplaySlider
          cssModule={[CoreStyles, AnimationStyles]}
          className="slider-home"
          play={true}
          cancelOnInteraction={false} // should stop playing on user interaction
          interval={4000}
          mobileTouch={true}
          organicArrows={true}
          bullets={false}
        >
          {backgrounds.map((e, i) => (
            <div
              key={i}
              data-src={e.img}
              className="row align-items-center"
              // onClick={(event) => history.push("/evento/" + e.id)}
              // style={{cursor: "pointer"}}
            >
              <div className="container slider-home-txt">
                <div className="row align-items-center justify-content-end">
                  <div className="col-12 col-lg-10">
                    <div className="hero-slides-content animate__animated animate__fadeIn">
                      <h6
                        className="date"
                        data-animation="fadeInUp"
                        data-delay="100ms"
                      >
                        {e.data}
                      </h6>
                      <h2 data-animation="fadeInUp" data-delay="500ms">
                        {e.title}
                      </h2>
                      <p data-animation="fadeInUp" data-delay="700ms">
                        {e.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </AutoplaySlider>
        {/* <div className="hero-slides owl-carousel  owl-loaded">
          <div
            className="single-hero-slide bg-img bg-overlay"
            style={{
              backgroundImage: `url(${backgrounds[0]})`,
            }}
          >
            <div className="container h-100">
              <div className="row h-100 align-items-center justify-content-end">
                <div className="col-12 col-lg-7">
                  <div className="hero-slides-content">
                    <h6
                      className="date"
                      data-animation="fadeInUp"
                      data-delay="100ms"
                    >
                      05.02.2021
                    </h6>
                    {/* <h3 data-animation="fadeInUp" data-delay="300ms">
                      Let God guide your path
                    </h3>
                    <h2 data-animation="fadeInUp" data-delay="500ms">
                      Congresso de Adolescentes
                    </h2>
                    <p data-animation="fadeInUp" data-delay="700ms">
                      Começa a XXª edição do Congresso de Adolescentes da CNIC ·
                      Congresso de Adolescentes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="single-hero-slide bg-img bg-overlay"
            style={{
              backgroundImage: `url(${backgrounds[1]})`,
            }}
          >
            <div className="container h-100">
              <div className="row h-100 align-items-center justify-content-end">
                <div className="col-12 col-lg-7">
                  <div className="hero-slides-content">
                    <h6
                      className="date"
                      data-animation="fadeInUp"
                      data-delay="100ms"
                    >
                      Todas as quintas-feiras
                    </h6>
                    <h2 data-animation="fadeInUp" data-delay="500ms">
                      Culto de Ensino
                    </h2>
                    <p data-animation="fadeInUp" data-delay="700ms">
                      Culto de ensino todas as quintas,Venha aprender e se
                      fortalecer com a palavra de Deus.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="single-hero-slide bg-img bg-overlay"
            style={{
              backgroundImage: `url(${backgrounds[2]})`,
            }}
          >
            <div className="container h-100">
              <div className="row h-100 align-items-center">
                <div className="col-12 col-lg-7">
                  <div className="hero-slides-content">
                    <h6
                      className="date"
                      data-animation="fadeInUp"
                      data-delay="100ms"
                    >
                      29.02.2021
                    </h6>
                    <h2 data-animation="fadeInUp" data-delay="500ms">
                      Retiro de Jovens 2021
                    </h2>
                    <p data-animation="fadeInUp" data-delay="700ms">
                      Prepare as suas malas, retiro nacional de jovens está
                      chegando. Grupos de jovens de diversas cidades reunidos
                      apenas em um lugar, para celebrar em comunhão tudo o que
                      Deus tem preparado para esses dias!
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div> */}
      </section>
    </>
  );
}

CarouselHome.propTypes = {
  // bla: PropTypes.string,
};

CarouselHome.defaultProps = {
  // bla: 'test',
};
