export const booksCNIC = [
  {
    id: 0,
    title: "Informativo CNIC.",
    img_capa: "img/files-img/05.JPG",
    url_book: "/files/pdfs/05.pdf",
  },
  {
    id: 1,
    title: "Informativo CNIC.",
    img_capa: "img/files-img/04.JPG",
    url_book: "/files/pdfs/04.pdf",
  },
  {
    id: 2,
    title: "Fé Esperança Amor.",
    img_capa: "img/files-img/02.JPG",
    url_book: "/files/pdfs/02.pdf",
  },
  {
    id: 3,
    title: "Glaube Hoffnung Liebe.",
    img_capa: "img/files-img/03.jpg",
    url_book: "/files/pdfs/03.pdf",
  },
  {
    id: 4,
    title: "Primeiro as uvas depois o vinho.",
    img_capa: "img/files-img/01.jpg",
    url_book: "/files/pdfs/01.pdf",
  },
];
