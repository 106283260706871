import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import PreLoader from "../PreLoader";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const menu = [
    {
      path: "/home",
      title: "Página Inicial",
    },
    {
      path: "/agenda",
      title: "Agenda",
    },
    {
      path: "/videos",
      title: "Videos",
      dropdown: [
        {
          path: "/videos/cultos",
          title: "Cultos Gravados",
        },
      ],
    },
    {
      path: "/pedidos-de-oracao",
      title: "Pedido de Oração",
    },
    {
      path: "/sobre",
      title: "A igreja",
    },
  ];
  useEffect(() => {}, [isOpen]);

  const Menu = () => (
    <ul>
      {menu.map((t, i) => (
        <li key={i}>
          <NavLink
            className="tags"
            activeStyle={{
              borderBottom: "5px solid #0667c5",
              color: "#0667c5",
            }}
            to={t.path}
            onClick={() => setIsOpen(false)}
          >
            {t.title}
          </NavLink>
          <ul className="dropdown">
            {t.dropdown &&
              t.dropdown.map((drop, index) => (
                <li key={index}>
                  <NavLink to={drop.path} onClick={() => setIsOpen(false)}>
                    {drop.title}
                  </NavLink>
                </li>
              ))}
          </ul>
        </li>
      ))}
      <li>
        <NavLink to="/boletins" onClick={() => setIsOpen(false)}>
          Boletins informativos
        </NavLink>
      </li>
    </ul>
  );
  return (
    <>
      <PreLoader />

      <header className="header-area">
        <div className="faith-main-menu">
          <div className="classy-nav-container breakpoint-off">
            <div className="container">
              <nav
                className="classy-navbar justify-content-between"
                id="faithNav"
              >
                <Link to="/home" className="nav-brand">
                  <img
                    width="95"
                    src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/logo.png?alt=media&token=368c1ced-bd38-46ae-bae7-7b93a2915181"
                    alt=""
                  />
                </Link>

                <div className="classy-mobile">
                  <a
                    className={isOpen ? "nav-icon active" : "nav-icon"}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <span></span>
                  </a>
                </div>
                {isOpen && (
                  <div className="classy-mobile-menu animate__animated animate__bounceInRight">
                    <Menu />
                  </div>
                )}

                <div className="classy-menu">
                  <div className="classynav">
                    <ul>
                      {menu.map((t, i) => (
                        <li key={i}>
                          <NavLink
                            className="tags"
                            activeStyle={{
                              borderBottom: "5px solid #0667c5",
                              color: "#0667c5",
                            }}
                            to={t.path}
                          >
                            {t.title}
                          </NavLink>
                          <ul className="dropdown">
                            {t.dropdown &&
                              t.dropdown.map((drop, index) => (
                                <li key={index}>
                                  <NavLink to={drop.path}>{drop.title}</NavLink>
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}
                    </ul>

                    <div className="donate-btn">
                      <NavLink to="/boletins">Boletins informativos</NavLink>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

Header.propTypes = {
  // bla: PropTypes.string,
};

Header.defaultProps = {
  // bla: 'test',
};
