import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import { ENV } from "./../../config/environments";

import emailjs from "emailjs-com";

export default function PedidosDeOracao() {
  const { register, handleSubmit, watch, errors } = useForm();
  const [success, setSuccess] = useState(false);

  function sendEmail(e) {
    //dashboard.emailjs.com/admin
    //www.emailjs.com/docs/examples/reactjs/F
    setSuccess(true);

    e.preventDefault();

    emailjs.sendForm("service_io9g84c", "template_sr9lpdm", e.target, "user_PbrorQT0y1QzyOFIEWnO2").then(
      (result) => {
        Swal.fire("Amém!", "Seu pedido de oração foi enviado com sucesso.", "success");
        console.log(result.text);
      },
      (error) => {
        Swal.fire("Ops! Houve um erro no envio do seu pedido de oração.", "Tente novamente ou fale conosco pelos contatos disponíveis no rodapé da página.", "error");
        console.log(error.text);
      }
    );
    setSuccess(false);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(() => {
      sendEmail(e);
    })(e);
  };

  const styleErrors = {
    color: "red",
    fontSize: "12px",
  };
  return (
    <>
      <div className="breadcumb-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcumb-text text-center mx-auto my-5">
                <img src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2Foracao.svg?alt=media&token=bca35d22-2264-48c3-a4d0-524094ed6769" width="8%" />
                <h3>Pedido de Oração</h3>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <section className="contact-area mt-100" style={{ marginBottom: "100px" }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="contact-info-area">
                <div className="row">
                  <div className="col-12 col-lg-5">
                    <div className="contact-information">
                      <h5>Contatos</h5>
                      {/* <div className="single-contact-area mb-30">
                        <p>Address:</p>
                        <span>
                          1481 Creekside Lane Avila <br />
                          Beach, CA 93424{" "}
                        </span>
                      </div> */}

                      <div className="single-contact-area mb-30">
                        <p>Telefones:</p>
                        <span>(55) 99148-8899</span>
                        <span>(55) 9921-9342</span>
                        <span>(45) 9944-9113</span>
                      </div>

                      <div className="single-contact-area mb-30">
                        <p>Emails:</p>
                        <br />
                        <b>Pastor Presidente Marcio Woltmann</b>
                        <span>Convencao.cnic@hotmail.com</span>
                        <span>marciowoltmann@yahoo.com.br</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-7">
                    <div className="contact-form-area">
                      {/* <hr className="my-5" /> */}
                      <h5>Faça seu pedido de Oração</h5>
                      <form onSubmit={onSubmit}>
                        <div className="mb-3">
                          <input name="name" placeholder="Seu nome" className="form-control" ref={register({ required: true })} />
                          {errors.name && <span style={styleErrors}>Campo obrigatório*</span>}
                        </div>
                        <div className="mb-3">
                          <textarea name="message" className="form-control" id="message" cols="30" rows="10" placeholder="Digite aqui seu pedido de oração" ref={register({ required: true })}></textarea>
                          {errors.message && <span style={styleErrors}>Campo obrigatório*</span>}
                        </div>
                        <br />
                        {success ? <img src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-gif%2F9d3903cdc587d449-high-quality-ajax-loader-images.gif?alt=media&token=667a74f6-afee-4ce0-b5d5-afc5a04b7d85" alt="loading" width="10%" /> : <button className="btn faith-btn">Enviar pedido</button>}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
