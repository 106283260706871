import React, { useEffect, useState } from "react";
import FB, { FacebookApiException } from "fb";
import ScrollToTop from "../../../components/ScrollToTop";

import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox";

import { useParams } from "react-router-dom";

export default function Fotos() {
  const { id } = useParams();

  const [album, setAlbum] = useState();
  const [photos, setPhotos] = useState();
  const [id_photo, setIdPhoto] = useState(id);

  const access_token =
    "EAALE5xU7VgkBAIjvSGQSUw54hLzoHra9ZAPdkjPD5iuq3JPLHXOX2g75msXnZA8J91FZBlnDJfZCILFKTjWOZAYiCSENN3yKRmJFAUOMCrpbZCiODpPIOZAoipcT5ZB4E0sY6dZBp43M3fqyZCDyuyQU210VDdFCjusNUqlzLVIUd9ZAEafC8Q061Dp";

  function fetchAlbumFB() {
    FB.api(
      `${id_photo}?fields=count,name&access_token=${access_token}`,
      function (response) {
        if (response && !response.error) {
          setAlbum(response);
        }
      }
    );
  }
  function handleFBFotos(paging) {
    let pagination = paging;

    FB.api(
      `${id_photo}/photos?fields=picture,alt_text,name,images,album&limit=54&access_token=${access_token}/${pagination}`,
      function (response) {
        if (response && !response.error) {
          setPhotos(response);
        }
      }
    );
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (!photos) {
      handleFBFotos("");
    }
  });

  useEffect(() => {
    if (!album) {
      fetchAlbumFB();
    }
  }, []);
  return (
    <>
      <div className="breadcumb-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcumb-text text-center mx-auto my-5">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/igrejadecristo-d93c0.appspot.com/o/core-img%2F9663a3aa79a6eb28d1d8722a34f55568.png?alt=media&token=d2aa2fe4-008e-4810-bc6e-fe4ce7f7b1b7"
                  width="8%"
                />
                <h3>Álbum</h3>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </div>
      <section className="container mt-50 mb-50">
        <div className="container">
          <div className="row">
            <h4>
              Fotos do álbum{" "}
              <strong>
                {album?.name &&
                  album?.name.replace(
                    /(^\w|\s\w)(\S*)/g,
                    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
                  )}
              </strong>
            </h4>
            <hr
              className="pt-0"
              style={{ borderTop: "1px solid rgb(0 0 0 / 15%)", width: "100%" }}
            />
          </div>
        </div>
        <div className="container mt-30">
          <SimpleReactLightbox>
            <SRLWrapper>
              <div className="row">
                {photos &&
                  photos?.data.map((photo, i) => (
                    <div className="col-md-3 mb-3" key={i} data-attribute="SRL">
                      <div
                        className="card albums animate__animated animate__fadeIn"
                        style={{ height: "200px" }}
                      >
                        <img
                          style={{ objectFit: "cover", height: "200px" }}
                          className="card-img-top"
                          src={photo?.images[0]?.source}
                          alt={photo?.album?.name}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>

        <div className="container mt-30">
          <div className="row">
            <nav
              aria-label="Page navigation example"
              className="d-flex justify-content-center"
              style={{ width: "100%" }}
            >
              <ul className="pagination">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => {
                      handleFBFotos(
                        "&before=" + photos?.paging?.cursors?.before
                      );
                    }}
                    disabled={photos?.paging?.previous ? false : true}
                  >
                    Anterior
                  </button>
                </li>
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => {
                      handleFBFotos("&after=" + photos?.paging?.cursors?.after);
                    }}
                    disabled={photos?.paging?.next ? false : true}
                  >
                    Próximo
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
    </>
  );
}
